$darkish-primary-color: hsl(350, 67%, 30%);
$primary-color: hsl(350, 67%, 58%);
$lightish-primary-color: hsl(350, 67%, 80%);
$light-primary-color: hsl(353, 100%, 94%);
$dark-secondary-color: hsl(84, 80%, 15%);
$darkish-secondary-color: #39580a;
$secondary-color: hsl(84, 80%, 31%);
$lightishish-secondary-color: hsl(84, 75%, 39%);
$lightish-secondary-color: hsl(84, 70%, 69%);
$light-secondary-color: hsl(84, 90%, 80%);
$dark-grain-color: hsl(49, 86%, 37%);
$fruit-background-color: hsl(350, 65%, 50%);
$fruit-text-color: #FDF1F2;
$vegetable-background-color: $darkish-secondary-color;
$vegetable-text-color: hsl(84, 52%, 69%);
$grain-background-color: hsl(49, 82%, 63%);
$grain-text-color: darken($grain-background-color, 32%);
$nut-background-color: hsl(32, 72%, 50%);
$nut-text-color: lighten($nut-background-color, 34%);
$spice-background-color: hsl(20, 72%, 47%);
$spice-text-color: lighten($spice-background-color, 40%);
$dark-vg-blend-color: #72740c;
$light-vg-blend-color: #b1c038;
$vg-blend-text-color: hsl(61, 51%, 56%);
$black-hover-color: #333;

$types: "fruit", "vegetable", "grain", "nut", "spice";

:root {
	--text-color: black;
	--text-background-color: white;
	--fruit-background-color: #{$fruit-background-color};
	--fruit-text-color: #{$fruit-text-color};
	--vegetable-background-color: #{$vegetable-background-color};
	--vegetable-text-color: #{$vegetable-text-color};
	--grain-background-color: #{$grain-background-color};
	--grain-text-color: #{$grain-text-color};
	--nut-background-color: #{$nut-background-color};
	--nut-text-color: #{$nut-text-color};
	--spice-background-color: #{$spice-background-color};
	--spice-text-color: #{$spice-text-color};
	--vg-blend-background-color: #{$dark-vg-blend-color};
	--vg-blend-text-color: #{$vg-blend-text-color};
}
body {
	background-color: $lightish-secondary-color;
	display: flex;
	flex-direction: column;
	font-family: sans-serif;
	margin: 0;
	min-height: 100vh;
}
[lang="en"] [lang]:not([lang="en"]) {
	font-style: italic;
}
header {
	/* Gradient version */
	// background: linear-gradient(90deg,
	// 	hsl(354, 80%, 50%) 20%,
	// 	hsl(25, 80%, 45%) 35%,
	// 	hsl(56, 80%, 39%) 50%,
	// 	hsl(87, 80%, 35%) 65%,
	// 	hsl(118, 79%, 28%) 80%);

	/* Split version */ // Should I use this instead?
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAABCAYAAAD0In+KAAAAEUlEQVQImWO445v23zKC6z8AE2IEKbO7jUwAAAAASUVORK5CYII=) center/cover;
	image-rendering: pixelated;
	image-rendering: crisp-edges;
	
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.26);
	padding: 10px;
	>* {
		align-items: center;
		display: inline-flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	a {
		text-decoration: none;
	}
	img {
		// filter: drop-shadow(2px 2px 0 white) drop-shadow(-2px 2px 0 white) drop-shadow(2px -2px 0 white) drop-shadow(-2px -2px 0 white);
		filter: drop-shadow(1px 1px 3px #000000c0);
		flex: 0 1 100px;
		max-width: 24vw;
	}
	$header-text-shadow: 2px 3px 6px rgba(0,0,0,.9);
	h1 {
		color: #EEE;
		display: inline-block;
		flex: 1 1 0;
		margin: 0;
		padding: 0 2vw;
		text-shadow: $header-text-shadow;
		vertical-align: top;
	}
	h1:hover {
		text-shadow: $header-text-shadow, 0 0 5px rgba(256,256,256,0.5);
	}
}
h1 {
	font-size: unquote("min(60px, 12vw)");
	line-height: unquote("min(60px, 12vw)");
}
header, footer {
	text-align: center;
}
main {
	flex-grow: 1;
	padding-bottom: 2vw;
}
section, article, details {
	background-color: var(--text-background-color);
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.26);
	color: var(--text-color);
	max-width: 700px;
	margin: 15px auto;
	padding: 10px;
	img {
		display: block;
		max-width: unquote("min(96vw, 700px)");
		margin: auto;
	}
}
h2, h2 a, h3, h4, h5, h6, summary {
	color: $primary-color;
	font-weight: bold;
	margin: 0 0 15px 0;
}
h2, summary, .question {
	font-size: 24px;
	font-weight: bold;
}
article > .question, p {
	margin: 16px 0;
}
summary {
	cursor: pointer;
}
details:not([open])>summary {
	margin: 0;
}
p:first-child {
	margin-top: 0;
}
p:last-child {
	margin-bottom: 0;
}
a {
	color: $secondary-color;
}
a:hover, button:hover {
	filter: brightness(1.4);
}
#popular-items {
	align-items: end;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	text-align: center;
	> a {
		text-decoration: none;
		font-weight: bold;
		> div {
			box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
			margin: 3px;
			padding: 4px;
		}
	}
	img {
		display: inline;
		height: 71px;
		width: 126px;
	}
}
#search-section form {
	display: flex;
	button {
		height: 30px;
		margin-left: 5px;
	}
}
#search-box {
	flex-grow: 1;
	font-size: 18px;
	height: 30px;
}
input[type="text"] {
	background-color: $light-secondary-color;
	color: $dark-secondary-color;
	border: 1px solid $secondary-color;
	padding: 4px 5px;
}
button {
	background-color: $secondary-color;
	border: none;
	box-shadow: 0 2px 3px 0 rgba(0,0,0,0.14);
	color: #EEE;
	cursor: pointer;
	font-weight: bold;
	padding: 5px 8px;
}
input[type="text"], button {
	box-sizing: border-box;
	height: 26px;
}
li:not(:last-child) {
	margin-bottom: 8px;
}
blockquote {
	background-color: $light-secondary-color;
	border-left: 4px solid $secondary-color;
	margin: 16px 8px;
	padding: 4px;
}
ol::marker {
	font-weight: bold;
}
.quote-signature::before {
	content: "—";
}
ul {
	list-style-image: url(/assets/tomato-bullet.png);
}
table {
	border-collapse: separate;
	border-spacing: 5px;
}
th, td {
	padding: 4px 7px;
	box-shadow: 0 2px 3px 0 rgba(0,0,0,0.24);
}
figure {
	margin: 0;
}
figcaption {
	color: #757575;
	margin-top: .6ex;
	text-align: center;
}
.type {
	box-shadow: 0 2px 3px 0 rgba(0,0,0,0.14);
	font-size: 20px;
	font-weight: bold;
	margin: 10px 3px;
	padding: 10px;
	> div:not(:first-child) {
		box-shadow: 0 2px 3px 0 rgba(0,0,0,0.28);
		margin-top: 10px;
		padding: 12px;
	}
	:not(.fruit):not(.vegetable):not(.grain):not(.nut)>.answer:not(.spice)>.answer a:hover {
		color: $black-hover-color;
	}
	
	.answer {
		font-size: 18px;
		font-style: italic;
		margin-top: 5px;
		margin-left: 10px;
		a {
			color: inherit;
			// text-decoration: none;
		}
		.answer-parent {
			font-size: 16px;
		}
	}
}
@each $type in $types {
	.#{$type} {
		background-color: var(--#{$type}-background-color);
	}
	.#{$type}, .#{$type} a {
		color: var(--#{$type}-text-color);
	}
}
.vegetable.grain {
	background-color: var(--vg-blend-background-color);
}
.vegetable.grain, .vegetable.grain a {
	color: var(--vg-blend-text-color);
}
.bacteria::before,
.fruit::before,
.grain:not(.vegetable)::before,
.seed::before,
.vegetable:not(.grain)::before {
	background-repeat: no-repeat;
	background-size: 50px 50px;
	content: "";
	float: left;
	height: 50px;
	padding-right: 10px;
	opacity: 0.9;
	width: 50px;
}
.bacteria::before {
	background-image: url(/assets/icons/bacteria.svg);
}
.fruit::before {
	background-image: url(/assets/icons/fruits.svg);
	filter: unquote("invert()");
}
.grain:not(.vegetable)::before {
	background-image: url(/assets/icons/grains.svg);
}
.seed::before {
	background-image: url(/assets/icons/seeds.svg);
}
.vegetable:not(.grain)::before {
	background-image: url(/assets/icons/vegetables.svg);
	filter: unquote("invert()");
}
.case {
	font-size: initial;
	font-weight: normal;
	margin-top: 7px;
}
.case-name {
	font-style: italic;
}
.see-also {
	background-color: $light-secondary-color;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.26);
	margin: -3px;
	padding: 6px 8px;
}
footer {
	align-items: center;
	background-color: $primary-color;
	color: #EEE;
	> div {
		display: flex;
		justify-content: center;
		> *, div {
			margin: 10px;
		}
	}
	a {
		color: $light-secondary-color;
	}
	img:hover {
		filter: brightness(2.4);
	}
}
@media (prefers-color-scheme: dark) {
	:root {
		--text-color: #EEE;
		--text-background-color: #333;
	}
	body {
		background-color: $darkish-secondary-color;
	}
	a {
		color: $lightish-secondary-color;
	}
	input[type="text"] {
		background-color: $darkish-primary-color;
		color: #FFF;
	}
	blockquote {
		background-color: $darkish-secondary-color;
	}
	.answer:hover {
		color: #FFF;
	}
	.bacteria::before {
		filter: unquote("invert()");
	}
	.seed::before {
		filter: unquote("invert()");
	}
	.see-also {
		background-color: $darkish-secondary-color;
	}
}